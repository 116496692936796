/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/no-cycle */
import {IGalleryStyleParams} from './types/galleryTypes';
import {getDefaultStyleParams} from './viewerScript/getDefaultStyleParams';

export function getStyleParamsWithDefaultsFunc({style: {styleParams}, dimensions}) {
  const overrides = {numbers: {}, booleans: {}, fonts: {}, ...styleParams};
  [
    'galleryColumns',
    'galleryRows',
    'galleryMargin',
    'galleryMarginColumn',
    'galleryMarginRow',
    'gallery_gapSize',
    'gallery_gapSizeColumn',
    'gallery_gapSizeRow',
    'gallery_imageWidth',
    'gallery_imageAndInfoSpacing',
  ].forEach((key) => {
    if (overrides.numbers[key] !== undefined) {
      overrides.numbers[key] = Math.round(overrides.numbers[key]);
    }
  });

  const {
    booleans: {showAlternativeImage, responsive: isEditorX},
    numbers: {
      galleryColumns,
      galleryRows,
      galleryMargin,
      galleryMarginRow,
      galleryMarginColumn,
      gallery_gapSize,
      gallery_gapSizeColumn,
      gallery_gapSizeRow,
      gallery_imageWidth,
      gallery_imageAndInfoSpacing,
      gallery_imageMode,
      galleryImageRatio,
      gallery_productMargin,
    },
    fonts: {gallery_titleFontStyle, gallery_priceFontStyle, gallery_addToCartButtonTextFont, gallery_ribbonTextFont},
  } = overrides as IGalleryStyleParams;

  /* istanbul ignore next: will be removed when specs.stores.GalleryUseYoshiStyleParamStandard is merged */
  const defaults = getDefaultStyleParams({
    showAlternativeImage: showAlternativeImage === undefined ? true : showAlternativeImage,
    galleryColumns,
    galleryRows,
    dimensions,
    isEditorX,
    galleryMargin,
    galleryMarginRow,
    galleryMarginColumn,
    gallery_gapSize,
    gallery_gapSizeColumn,
    gallery_gapSizeRow,
    gallery_titleFontStyle,
    gallery_priceFontStyle,
    gallery_addToCartButtonTextFont,
    gallery_ribbonTextFont,
    gallery_imageWidth,
    gallery_imageAndInfoSpacing,
    gallery_imageMode,
    galleryImageRatio,
    gallery_productMargin,
  });

  return {defaults, overrides};
}
